<template>
  <div class="main clearfix">
    <!-- <CurrentLocation /> -->
    <!-- <div class="box"> -->
      <ul class="list-4">
      <li
        v-for="(item, index) in data.list"
        :key="index"
        :data-index="index"
        :title="item.title"
      >
        <router-link
          :to="{
            path: item.router,
            query: { id: item.id },
          }"
          :title="item.title"
        >
              <a class="title-s">{{ item.title }}</a>
              <div class="title-describes-s">
                {{item.abstracts}}</div>
          <!-- <span class="title-date" style="margin-left: 20px;">{{ dateFormat(item.publishTime, "YYYY-MM-DD") }}</span> -->
        </router-link>
      </li>
    </ul>
  <!-- </div> -->
  </div>
</template>

<script>
import { onMounted, reactive, toRefs } from "vue";
import { useRouter } from 'vue-router';
import CurrentLocation from "../layout/components/CurrentLocation";
import PanelNav from "../layout/components/PanelNav";
// import PanelLast from "../layout/components/PanelLast";
import PanelList from "../layout/components/PanelList";
import dateFormat from "@/utils/dateFormat";

import axios from "@/api/apis";

export default {
  name: "searchresult",
  components: { CurrentLocation, PanelNav, PanelList },
  created() {},
  setup() {

    const router = useRouter()
    const searchKey = router.currentRoute.value.query.search

    const state = reactive({
      title: "搜索结果",
      data: {
        currentPage: "",
        size: "",
        total: "",
        list: [],
        Results:[],
      },
    });
    const searchResult = () => {
      
      axios
        .post("https://cqgdrm.com/api/website/article/page", { group: 1, pageSize: 5, current: 1 })
        .then(function (data) {
          
          console.log(searchKey)   //searchKey 是首页搜索框传过来的值
          state.data.currentPage = data.currentPage;
          console.log(data.currentPage);
          state.data.size = data.size;
          state.data.total = data.total;
          console.log(data.total);
          data.data.forEach((item) => {
          // 根据首页的搜索关键字获取数据
          if(item.title.indexOf(searchKey) != -1) {  //title里有搜索关键字
              console.log(item.title);
              state.data.list.push({
              id: item.id,
              title: item.title,
              type: item.type,
              abstracts:item.abstracts,
              content: item.content,
              createdTime: item.createdTime,
              router: "/news/company/details",
              });
            }
          });
        })
        .catch(function (error) {
          console.log(error);
        });
    };
 
    onMounted(() => {
     
      searchResult();
      
    });
    return {
      ...toRefs(state),
    };
  },
};
</script>

<style scoped>
.title-s{
    width: 1200px;
    color: #000;
    font-size: 18px;
    overflow:hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    -o-text-overflow:ellipsis;
}
.title-describes-s{
    /* flex: 1; */
    width: 1200px;
    height: 50px;
    line-height: 30px;
    /* margin-left: 20px; */
    color: #5b5b5b;
    word-wrap:break-word;
    white-space:normal;
}
.list-4{
  width: 1200px;
  margin: 0 auto;
  /* margin-top: 10px; */
}
.list-4 li {
    border-bottom: #d9d9d9 dotted 1px;
    list-style-type: none;
    display: flex;
    padding: 5px;
    /* height: 240px; */
    margin-top: 10px;
  }
</style>
